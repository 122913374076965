<template>
  <div class="assessBox">
    <div class="app-container"
         style="background:#fff">
      <el-form v-model="matchForm"
               ref="matchForm"
               :disabled="isDisabled">
        <div class="qualityevaluation">
          <div v-for="(question ,index) in questions"
               :key="index">
            <div class="title">{{question.className}}</div>
            <el-form-item v-for="(indicator ) in question.indicatorList"
                          :key="indicator.indicatorId "
                          :label="indicator.indicatorName">
              <div class="auditRaido">
                <el-radio-group style="width:97%"
                                @change="radiochange(indicator.indicatorId)"
                                v-model="answerList[indicator.indicatorId].selectedOptionId"
                                class="radioArr">
                  <el-radio v-for="(option ,optionIndex) in indicator.optionList"
                            :key="optionIndex"
                            :label="option.optionId">
                    <span style="color:#000;padding:0 5px;">{{option.content}}</span>
                  </el-radio>
                </el-radio-group>
                <span :id=" isDisabled ? '': 'radio_'+indicator.indicatorId"
                      style="color:red;display:none">请选择选项</span>
              </div>
              <el-form-item label="描述依据"
                            class="fwn">
                <div>
                  <el-input style="margin-top:-12px;"
                            type="textarea"
                            :autosize="{ minRows: 3, maxRows: 4}"
                            maxlength="200"
                            @change="remarkChange($event,indicator.indicatorId)"
                            show-word-limit
                            v-model="answerList[indicator.indicatorId].remark"></el-input>
                </div>
                <span :id="isDisabled ? '':'textarea_'+indicator.indicatorId"
                      style="color:red;display:none">请填写内容</span>
              </el-form-item>
            </el-form-item>
            <el-divider></el-divider>
          </div>
          <div class="buttonBox"
               v-show="!isDisabled">
            <el-button type="plain"
                       size="medium"
                       class="buttonBox_cancel"
                       @click="$router.go(-1)">取消</el-button>
            <el-button type="primary"
                       size="medium"
                       @click="submit()">提交</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import * as api from '@/api/peopelPostMatch';
export default {
  props: {
    questions: {
      type: Array
    },
    answerList: {},
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      }
    },
    Viewform: {}
  },
  data () {
    return {
      currentItem: {},
      matchForm: [],
      radioTag: '',
      textaraTag: ''
    };
  },
  watch: {
  },
  computed: {
  },
  created () { },
  mounted () {
  },
  methods: {
    clearValidate () {
      this.$refs.matchForm.clearValidate();
    },
    radiochange (indicatorId) {
      document.getElementById(`radio_${indicatorId}`).style.display = 'none';
    },
    remarkChange (remark, indicatorId) {
      if (remark != '') {
        document.getElementById(`textarea_${indicatorId}`).style.display = 'none';
      }
    },
    submit () {
      let valid = true;

      const list = [];

      for (const i in this.answerList) {
        list.push(this.answerList[i]);
      }
      list.forEach((val) => {
        if (val.selectedOptionId == '') {
          document.getElementById(`radio_${val.indicatorId}`).style.display = 'block';
          valid = false;
        } else {
          document.getElementById(`radio_${val.indicatorId}`).style.display = 'none';
        }
        if (val.remark == '') {
          document.getElementById(`textarea_${val.indicatorId}`).style.display = 'block';
          valid = false;
        } else {
          document.getElementById(`textarea_${val.indicatorId}`).style.display = 'none';
        }
      });

      const params = {
        request: {
          userId: this.Viewform.id,
          comId: this.Viewform.comId,
          assessorType: 1,
          type: 2,
          list
        }
      };

      if (valid) {
        api.save(params).then(() => {
          this.$message.success('人才评估提交成功');
          this.$router.push({ path: '/peoplepostmatch' });
        });
      }

    }
  }
};
</script>

<style lang="scss" scoped>
.qualityevaluation {
  padding: 0 !important;
}
</style>

<style lang="scss">
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
</style>

<style lang="scss">
.level_box {
  display: flex;
  // background-color: pink;
  .level_box_now {
    // color:#4A8DF0;
    color: #409eff;
    font-weight: bold;
    font-size: 18px;
  }
  .level_box_old {
    color: #ccc;
    font-weight: bold;
    font-size: 18px;
    margin-left: 40px;
  }
  .level_box_btnbox {
    flex: 1;
    display: inline;
    text-align: right;
    // background-color: blue;
    .level_box_btnbox_audit {
      width: 80px;
      // height: 40px;
    }
    .level_box_btnbox_export {
      width: 80px;
    }
  }
  .level_box_levelbox {
    flex: 1;
  }
}
</style>

<style lang="scss">
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;

    .el-form {
      .is-error {
        margin: 30px 0 !important;
      }
      .viewbox {
        .is-error:last-of-type {
          margin-bottom: 0 !important;
        }
      }
      .fwn {
        margin-left: 30px;
        .el-form-item__label {
          font-weight: normal;
          // transform: translateY(-24px);
          transform: translateY(-10px);
          width: 100%;
          text-align: left;
        }
      }
    }
    .titlebox {
      text-align: center;
      line-height: 17px;
      // background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin: 20px;
      // .title {
      // }
    }
    .viewbox {
      .is-required:last-of-type {
        margin-bottom: 0 !important;
      }
      padding: 30px 0 0 140px;
      // background: #fff;
      margin: 0 20px;
      .el-radio {
        margin-right: 20px;
      }
      > p {
        color: #4a8df0;
        font-weight: bold;
        font-size: 18px;
      }
      > div {
        margin-bottom: 14px;
        font: bold 14px/25px "Microsoft YaHei";
        span {
          font-weight: normal;
        }
      }
      > div:nth-child(8) {
        margin-bottom: 0;
      }
      .title {
        font: bold 18px/25px "Microsoft YaHei";
        color: #484848;
      }
      .ptitle {
        font: bold 14px/25px "Microsoft YaHei";
        margin-bottom: 10px;
      }

      .el-textarea {
        width: 80%;
        // margin-left: 10px;
        vertical-align: middle;
        .el-textarea__inner {
          background-color: #f9fbff;
        }
      }
      > .el-textarea {
        width: 80%;
      }
      .radioArr {
        margin-left: 30px;
        margin-bottom: 10px;
        .el-radio {
          margin-right: 40px;
        }
      }
    }
    .qualityevaluation {
      .is-error {
        margin-top: 0 !important;
      }
      div {
        font-weight: bold;
        span {
          font-weight: normal;
          font-size: 14px;
        }
      }
      .title {
        font: bold 18px/25px "Microsoft YaHei";
        margin-bottom: 10px;
      }
      .ptitle {
        font: bold 14px/25px "Microsoft YaHei";
        margin: 10px 0;
      }
      .el-textarea {
        width: 80%;
        margin: 5px 0px;
        vertical-align: middle;
        .el-textarea__inner {
          background-color: #f9fbff;
        }
      }
      .big-textarea {
        display: block;
        width: 80%;
        margin-left: 30px;
      }
      .radioArr {
        .el-radio {
          width: 100%;
          margin: 10px 0;
        }
      }
      .Talentstype {
        margin-top: 15px;
        .el-form-item__label {
          font-size: 18px;
        }
        .radioArr {
          width: 80%;
          margin-left: 10px;
          .el-radio {
            width: 25%;
            margin: 10px 5px;
          }
        }
      }
      .buttonBox {
        margin-top: 20px;
        padding: 0 25%;
        .el-button {
          width: 100px;
        }
      }
    }
    .Postproposal {
      margin-top: 15px;

      .el-form-item__label {
        font-size: 18px;
      }
      .el-textarea {
        margin-left: 30px;
        display: block;
        width: 80%;
        .el-textarea__inner {
          background-color: #f9fbff;
        }
      }
      .radioArr {
        width: 80%;
        margin-left: 10px;
        .el-radio {
          width: 25%;
          margin: 10px 5px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.assessBox .app-container .viewbox .viewbox_position {
  color: #3f3f3f;
  font-weight: normal;
}
.assessBox .app-container .qualityevaluation .buttonBox_cancel {
  color: #c5cdda;
  margin-right: 10%;
}

.assessBox .app-container .qualityevaluation .Postproposal_input {
  margin-top: 20px;
}
// 覆盖elementUI的样式
.assessBox {
  // background-color: #fff;
  .app-container {
    .el-radio__label {
      color: #3f3f3f;
    }
  }
}
</style>
