<template>
  <div class="container">
    <div class="titlebox">
      <el-button size="medium"
                 type="text"
                 @click="$router.go(-1)">返回</el-button>
      <div class="title">{{Viewform.userName}}-评估审核</div>
    </div>
    <!-- <div class="divider_line"></div> -->
    <div class="container_box">
      <!-- 原始评估模块 -->
      <div class="section_assessment">
        <!-- <div class="section_assessment_title">原始评估</div> -->
        <div class="viewbox">
          <!-- <div class="level_box">
            <div class="level_box_levelbox">
              <span class="level_box_now">评估职级：{{rankName==0?'副总':rankName==1?'总监':rankName==2?'经理':rankName==3?'主管':'员工'}}级</span><span class="level_box_old">原有职级：{{originRankName||"暂无评"}}级</span>
            </div>
          </div> -->
          <matchform :answerList="answerList" :questions="questions"
                       :isDisabled="true"></matchform>
        </div>

        <!-- <div class="evaluator">
          <div>
            <span class="evaluator_name">评估人：{{evaluateUsername}}</span><span>评估时间：{{evaluateTime}}</span>
          </div>
          <div style="margin-top:7px;">
            <span class="evaluator_name"
                  v-show="auditor">审核人：{{auditor}}</span><span v-show="auditTime">审核时间：{{auditTime}}</span>
          </div>
        </div> -->
      </div>
      <!-- -------------------------------------------------------------------------------------- -->
      <!-- 评估审核模块 -->
      <div class="section_audit">
        <!-- <div class="section_assessment_title">评估审核</div> -->
        <div class="viewbox">
          <!-- <div class="level_box">
            <div class="level_box_levelbox">
              <span class="level_box_now">评估职级：{{rankName==0?'副总':rankName==1?'总监':rankName==2?'经理':rankName==3?'主管':'员工'}}级</span><span class="level_box_old">原有职级：{{originRankName||"暂无评"}}级</span>
            </div>
          </div> -->
          <matchform :Viewform="Viewform" :questions="questions" :answerList="newAnswerList"
                       :isDisabled="false"></matchform>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/peopelPostMatch';
import matchform from '../matchForm.vue';
export default {
  components: {
    matchform
  },
  data () {
    return {
      userId: '',
      // 原始评估的数据
      talentType: '',
      rankName: '0',
      rankLevel: '1',
      originRankName: '',
      rankNote: '',
      competencyNote: '',
      synthesizeAppraise: '',
      appointment: '',
      firstResport: {},
      againResport: {},
      appointmentNote: '',
      evaluateUsername: '',
      evaluateTime: '',
      auditor: '',
      auditTime: '',

      talentTypeAgain: '',
      rankNameAgain: '0',
      rankLevelAgain: 1,
      rankNoteAgain: '',
      competencyNoteAgain: '',
      synthesizeAppraiseAgain: '',
      appointmentAgain: '',
      PostproposalAgain: '',
      appointmentNoteAgain: '',
      questions: [],
      answerList: {},
      newAnswerList: [],
      Viewform: {
        id: '',
        assessorType: '',
        userName: '',
        comId: ''
      }
    };
  },
  mounted () {
    if (this.$route.query) {
      this.Viewform.id = this.$route.query.id;
      this.Viewform.userName = this.$route.query.userName,
      this.Viewform.comId = this.$route.query.comId;
    }
    this.getFormDate();
  },
  watch: {
    rankNameAgain (val) {
      if (val != 0) {
        this.competencyNoteAgain = '';
      }
    }
  },
  methods: {
    getFormDate () {
      api.getConfig({request: {
          userId: this.Viewform.id
        } }).then((res) => {
        this.questions = res.classList;
        this.questions.forEach((indicator) => {
            indicator.indicatorList.forEach((val) => {
              this.$set(this.answerList, val.indicatorId, {
                'indicatorId': val.indicatorId,
                'selectedOptionId': '',
                'remark': ''
              });
              this.$set(this.newAnswerList, val.indicatorId, {
                'indicatorId': val.indicatorId,
                'selectedOptionId': '',
                'remark': ''
              });
            });
          });
        this.getDetail();
      });
    },
    // 获取数据回显
    getDetail () {
      api.getAnswerInfo({request: {
          userId: this.Viewform.id
        }}).then((res) => {
        if (res.selfList.length != 0) {
          res.selfList.forEach((val) => {
            this.$set(this.answerList, val.indicatorId, {
                'indicatorId': val.indicatorId,
                'selectedOptionId': val.selectedOptionId,
                'remark': val.remark
              });
          });
        }
        if (res.superiorList.length != 0) {
          res.superiorList.forEach((val) => {
            this.$set(this.newAnswerList, val.indicatorId, {
                'indicatorId': val.indicatorId,
                'selectedOptionId': val.selectedOptionId,
                'remark': val.remark
              });
          });
        }
      });
    },
    handleCancel () {
      this.$router.go(-1);
    }

  }
};
</script>

<style lang="scss">

// 覆盖原来elementUi的样式
.el-radio {
  color: #3f3f3f;
}
.container .container_box .el-radio__input.is-disabled + span.el-radio__label {
  color: #3f3f3f;
}
</style>

<style lang="scss" scoped>
.container {
  color: #484848;
  // background-color: #F6F7FB;
  .level_box {
    display: flex;
    // background-color: pink;
    .level_box_now {
      color: #4a8df0;
      font-weight: bold;
      font-size: 18px;
    }
    .level_box_old {
      color: #ccc;
      font-weight: bold;
      font-size: 18px;
      margin-left: 40px;
    }
    .level_box_levelbox {
      flex: 1;
    }
  }
  top: -24px;
  // }
  .tw24 {
    display: block;
    // position: relative;
    // top: -24px;
  }
  .btn_back {
    font-size: 16px;
    color: #484848;
    margin-top: 20px;
    margin-left: 30px;
  }
  .titlebox {
    text-align: center;
    line-height: 17px;
    background: #fff;
    font: bold 18px/25px "Microsoft YaHei";
    padding: 14px 40px;
    > .el-button {
      float: left;
      color: #484848;
      transform: translateY(-8px);
      font-size: 16px;
    }
    text-align: center;
    line-height: 17px;
    font: bold 18px/25px "Microsoft YaHei";
    margin: 20px;
  }
  .container_box {
    display: flex;
    padding: 30px 0 0 0;
    background: #fff;
    margin: 0 20px;
    .el-radio__label {
      color: #3f3f3f;
    }
  }
  .section_assessment {
    width: 50%;
    border-right: 1px solid #ccc;
    .evaluator {
      text-align: left;
      font: bold 14px/23px "Microsoft YaHei";
      padding: 20px 0px;
      padding-left: 40%;
      .evaluator_name {
        margin-right: 20px;
        font-weight: bold;
        min-width: 113px;
        display: inline-block;
      }
    }
  }
  .section_audit {
    width: 50%;
  }
}
</style>
<style lang="scss">
.section_assessment,
.section_audit {
  > .el-button {
    float: left;
    color: #484848;
    transform: translateY(-8px);
    font-size: 16px;
  }
  .viewbox {
    padding: 10px 20px 0 40px;
    width: 100%;
    .el-radio {
      margin-right: 20px;
    }
    > p {
      color: #409eff;
      font-weight: bold;
      // font-size: 18px;
      font: bold 18px/25px "Microsoft YaHei";
    }
    > div {
      margin-bottom: 14px;
      font: bold 14px/25px "Microsoft YaHei";
      .viewbox_position {
        color: #3f3f3f;
        font-weight: normal;
      }
      span {
        font-weight: normal;
      }
    }
    .title {
      font: bold 18px/25px "Microsoft YaHei";
    }
    .ptitle {
      font: bold 14px/25px "Microsoft YaHei";
      margin-bottom: 10px;
    }

    .el-textarea {
      width: 87%;
      margin-left: 0px;
      vertical-align: middle;
      .el-textarea__inner {
        background-color: #f9fbff;
        // width: 380px;
      }
    }
    > .el-textarea {
      width: 87%;
      margin-left: 0;
    }
    .auditRaido{
      .radioArr {
      margin-left: 30px;
      margin-bottom: 10px;
      .el-radio {
        margin-right: 40px;
        width:100%;
        white-space: pre-wrap;
        text-indent: -14px;
        line-height: 20px;
      }
    }
    }
  }
}
</style>
